import { useAppDispatch, useAppSelector } from "app/hooks";
import InputField from "components/InputField";
import {
  setEmail,
  selectEmail,
  registerLegacyAsync,
  selectErrors,
  selectHasRegistered,
  selectAlternatePassword,
  setAlternatePassword,
  selectErrorCodes,
} from "features/getmail/getMailSlice";
import {
  selectLegacyMail,
  selectLogin,
  selectPassword,
} from "features/login/loginSlice";
import React from "react";
import { t } from "@lingui/macro";
import { getRedirectionTarget } from "features/login/CheckLogin";
import { Trans as TransReact } from "@lingui/react";
import SkeletonComponent from "features/page/SkeletonComponent";
import { PREDEFINED } from "features/page/LinkList";
import FormWrapper from "features/page/FormWrapper";

const GetMail = function () {
  const hasToChangePassword = true;
  const email = useAppSelector(selectEmail);
  const name = useAppSelector(selectLogin);
  const legacyMail = useAppSelector(selectLegacyMail);
  const password = useAppSelector(selectPassword);
  const alternatePassword = useAppSelector(selectAlternatePassword);
  const dispatch = useAppDispatch();
  /* if email adresse found on legacy data, show it here */
  if (legacyMail && !email) {
    dispatch(setEmail(legacyMail));
  }
  const register = () => {
    dispatch(
      registerLegacyAsync({
        username: name,
        encodedPassword: btoa(password),
        email: email.length < 1 ? legacyMail : email,
        alternatePassword,
      })
    );
  };
  const errors = useAppSelector(selectErrors);
  const errorCodes = useAppSelector(selectErrorCodes);
  const getErrors = () => {
    if (errorCodes.length === 1 && errorCodes[0] === 4000001) {
      return (
        <>
          <span className="error-message">
            <TransReact id={"getmail.error.invalid_userdata"} />
          </span>
        </>
      );
    } else if (errors.length > 0) {
      return (
        <>
          {errors.map((e, i) => (
            <span key={`error_${i}`} className="error-message">
              <TransReact id={e} />
            </span>
          ))}
        </>
      );
    } else {
      return <span />;
    }
  };
  if (useAppSelector(selectHasRegistered)) {
    window.location.href = getRedirectionTarget("legacy");
  }
  const tokens = {
    0: <br />,
    1: <strong />,
  };
  return (
    <SkeletonComponent
      prefix={"getMail"}
      links={[
        {
          text: t`links.legacy.bypass.text`,
          target: getRedirectionTarget("legacy"),
          isHref: true,
        },
        PREDEFINED.SUPPORT,
      ]}
    >
      <FormWrapper
        className="ask-email"
        onBtnClick={register}
        okText={"Abschicken"}
      >
        <InputField
          dispatchTo={setEmail}
          selector={selectEmail}
          name="login"
          type={"email"}
          autoComplete={"email"}
          label={t`email`}
        />
        {hasToChangePassword && (
          <InputField
            dispatchTo={setAlternatePassword}
            selector={selectAlternatePassword}
            name="password"
            autoComplete={"current-password"}
            type={"password"}
            label={t`password`}
            onEnter={register}
          />
        )}
      </FormWrapper>
      {hasToChangePassword && (
        <div>
          <p>
            <TransReact
              id={`getmail.error.see_below`}
              components={tokens}
              values={tokens}
            />
          </p>
          <p className="error-message">
            <TransReact
              id={`getmail.error.bad_password`}
              components={tokens}
              values={tokens}
            />
          </p>
          <br />
        </div>
      )}
      {getErrors()}
    </SkeletonComponent>
  );
};

export default GetMail;
