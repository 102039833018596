import {
    finishFlowAsync,
    selectHasRun,
    selectSuccess,
} from 'features/verify/verificationSlice';
import SkeletonComponent from 'features/page/SkeletonComponent';
import React, {useEffect} from 'react';
import {useSearchParams} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from 'app/hooks';

const Verify = () => {
    const [params] = useSearchParams();
    const flowId = params.get('flow');
    const token = params.get('token');
    const dispatch = useAppDispatch();
    const hasRun = useAppSelector(selectHasRun);
    const success = useAppSelector(selectSuccess);

    const getContent = () => {
        if (!flowId || !token) {
            return <span>Something went srsly wrong</span>;
        }
        if (hasRun && !success) {
            return <span>Verification has failed</span>;
        }
        return <span>id: {flowId}</span>;
    };


    const finishVerificationFlow = (token: string, flowId: string) => {
        dispatch(finishFlowAsync({token, flowId}));
    };

    useEffect(() => {
        if (flowId && token) {
            finishVerificationFlow(token, flowId);
        }
    }, []);

    return (
        <SkeletonComponent
            prefix="verification"
            links={[]}
        >
            { getContent() }
        </SkeletonComponent>
    );
};


export default Verify;
