import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from 'app/store';
import axios from 'axios';
import IdentityDTO from './dto/Identity.dto';
import WhoAmIResponseDTO from './dto/WhoAmIResponse.dto';

export interface AccountState {
  password: string;
  pwRepeat: string;
  updateFailed: boolean;
  errors: string[];
  updateSucceeded: boolean;
  isFetchingAccount: boolean;
  identity?: IdentityDTO;
}
const initialState: AccountState = {
    password: '',
    updateFailed: false,
    updateSucceeded: false,
    errors: [],
    pwRepeat: '',
    isFetchingAccount: false,
};
export type AccountData = {
  csrf: string,
  flowId: string,
  password: string,
};
export type AccountResponse = {
  success: true,
}
export const accountSlice = createSlice({
    name: 'account',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
        setPassword: (state, action: PayloadAction<string>) => {
            state.password = action.payload;
        },
        setPasswordRepeat: (state, action: PayloadAction<string>) => {
            state.pwRepeat = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(updateAsync.pending, (state) => {
                state.isFetchingAccount = true;
                state.errors = [];
                state.updateFailed = false;
                state.updateSucceeded = false;
            })
            .addCase(updateAsync.fulfilled, (state, action) => {
                state.isFetchingAccount = false;
                if (!action || !action.payload) {
                    state.updateFailed = true;
                    state.errors = ['No error received'];
                    return;
                }
                if (Object.prototype.hasOwnProperty.call(action, 'payload')) {
                    state.updateFailed = !action.payload.success;
                    state.updateSucceeded = action.payload.success;
                }
            })
            .addCase(updateAsync.rejected, (state, action) => {
                state.isFetchingAccount = false;
                state.errors = ['129'];
                state.updateSucceeded = false;
                state.updateFailed = true;
                if (Object.prototype.hasOwnProperty.call(action, 'payload')) {
                    state.errors = action.payload?.errors || [];
                }
            })
            .addCase(getAccountFlowAsync.pending, (state) => {
                state.isFetchingAccount = true;
            })
            .addCase(getAccountFlowAsync.fulfilled, (state, action) => {
                state.isFetchingAccount = false;
                if (!action || !action.payload) {
                    state.updateFailed = true;
                    state.errors = ['No error received'];
                    return;
                }
                state.identity = action.payload;
            })
        ;
    },
});

export type PWFlowProperties = {
  password: string,
};
type AccountFailedResponse = {
    success: false,
    errors: string[]
};
export const updateAsync = createAsyncThunk<AccountResponse, PWFlowProperties, {rejectValue: AccountFailedResponse}>(
    'account/submitNewPassword',
    async (data:PWFlowProperties, {rejectWithValue}) => {
        try {
            const response = await axios.patch('/janus/account', data, {
                withCredentials: true,
            });
            return {
                success: response.status === 200,
            } as AccountResponse;
        } catch (e) {
            return rejectWithValue(e.response.data);
        }
    },
);

export const getAccountFlowAsync = createAsyncThunk<IdentityDTO|undefined>(
    'account/get',
    async () => {
        const p = window.location.protocol;
        const idService = window.location.host.split(/\./).slice(1).reverse().concat('id').reverse().join('.');
        try {
            const response = await axios.get<WhoAmIResponseDTO>(
                `${p}//${idService}/sessions/whoami`,
                {
                    withCredentials: true,
                },
            );
            return response.data.identity;
        } catch (e) {
            return undefined;
        }
    },
);

export const selectUpdateFailed = (state: RootState) => state.account.updateFailed;
export const selectUpdateSucceeded = (state: RootState) => state.account.updateSucceeded;
export const selectErrors = (state: RootState) => state.account.errors;

export const selectPassword = (state: RootState) => state.account.password;
export const selectPasswordRepeat = (state: RootState) => state.account.pwRepeat;

export const selectIdentity = (state: RootState) => state.account.identity;
export const selectIsFetchingAccount = (state: RootState) => state.account.isFetchingAccount;

export const {setPassword, setPasswordRepeat} = accountSlice.actions;
export default accountSlice.reducer;
