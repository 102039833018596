const isDebugging = () => window && window.localStorage && window.localStorage.getItem('debug') === '1';

const debug = (...args:unknown[]) => {
    // eslint-disable-next-line
    console.log(args);
};

export {
    isDebugging,
    debug,
};
