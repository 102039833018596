import { useAppDispatch, useAppSelector } from "app/hooks";
import InputField from "components/InputField";
import {
  getAccountFlowAsync,
  selectIdentity,
  selectIsFetchingAccount,
  selectPassword,
  selectPasswordRepeat,
  updateAsync,
  setPassword,
  setPasswordRepeat,
  selectUpdateSucceeded,
  selectErrors,
} from "features/account/accountSlice";
import FormWrapper from "features/page/FormWrapper";
import SkeletonComponent from "features/page/SkeletonComponent";
import React, { useEffect } from "react";
import { t } from "@lingui/macro";
import { Trans } from "@lingui/react";
import { LinkDTO, PREDEFINED } from "features/page/LinkList";
import { useNavigate } from "react-router-dom";

const isStrongPassword = (password: string): boolean => {
  if (password.length < 8) {
    return false;
  }
  if (password.match(/[A-Z]/) === null) {
    return false;
  }
  if (password.match(/[a-z]/) === null) {
    return false;
  }
  return true;
};

const Account = () => {
  const newPassword = useAppSelector(selectPassword);
  const newPasswordRepeat = useAppSelector(selectPasswordRepeat);
  const dispatch = useAppDispatch();
  const identity = useAppSelector(selectIdentity);
  const isLoading = useAppSelector(selectIsFetchingAccount);
  const isSuccessful = useAppSelector(selectUpdateSucceeded);
  const errors = useAppSelector(selectErrors);
  const navigate = useNavigate();
  const onSubmit = () => {
    if (!isStrongPassword(newPassword) || newPassword !== newPasswordRepeat) {
      return;
    }
    dispatch(
      updateAsync({
        password: newPassword,
      })
    );
  };
  useEffect(() => {
    dispatch(getAccountFlowAsync());
  }, [dispatch]);
  if (isLoading) {
    return <span>{t`loading`}</span>;
  }
  if (!identity) {
    return <span>{t`error.code.512`}</span>;
  }
  const tokens = {
    0: <br />,
    1: <strong />,
  };
  const logout = () => {
    const p = window.location.protocol;
    const u = window.location.hostname;
    window.location.href = `${p}//${u}/janus/login/logout`;
  };
  const toShop = () => {
    navigate("/");
  };
  const links: LinkDTO[] = [PREDEFINED.LOGOUT_BACKLINK, PREDEFINED.SUPPORT];
  return (
    <SkeletonComponent
      explanation="account.explanation"
      title="Account"
      prefix=""
      links={links}
    >
      <div className="moved-left">
        <h3>Passwort</h3>
        {!isSuccessful && (
          <FormWrapper
            className="lost-password"
            onBtnClick={onSubmit}
            okText={"Passwort ändern"}
          >
            <InputField
              name="password"
              type="password"
              label={t`account.form.password`}
              selector={selectPassword}
              dispatchTo={setPassword}
              validator={(v) => isStrongPassword(v)}
              errorGetter={() => t`account.password.weak`}
              validateWhileTyping={true}
            />
            <InputField
              name="password_1"
              type="password"
              label={t`account.form.password.repeat`}
              selector={selectPasswordRepeat}
              dispatchTo={setPasswordRepeat}
              validator={(v) => v === newPassword}
              errorGetter={() => t`account.password.repeat.mismatch`}
              validateWhileTyping={true}
            />
          </FormWrapper>
        )}
        {isSuccessful && (
          <div>
            <p>{t`account.password.changed.successfully`}</p>
            <div className={"form-component"} style={{ width: "100%" }}>
              <div
                tabIndex={0}
                className={`cl-button cl-button-animated `}
                style={{ maxWidth: "50%", width: "100%", marginTop: "0px" }}
                onClick={toShop}
                onKeyPress={(e) => {
                  if ([" ", "Enter"].includes(e.key)) {
                    toShop();
                  }
                }}
              >
                <div>{t`account.button.toshop`}</div>
              </div>
            </div>
          </div>
        )}
        {errors.length > 0 && (
          <span className="login_failed_msg error-message">
            <Trans
              id={`error.code.${errors[0]}`}
              components={tokens}
              values={tokens}
            />
          </span>
        )}
      </div>
    </SkeletonComponent>
  );
};

export default Account;
