let isInitialised = false;
export const injectGTMSnippets = (gtmId: string): void => {
    if (!gtmId) {
        console.error('Google Tag Manager ID is missing');
        return;
    }
    // try this only once:
    if (isInitialised) {
        return;
    }
    isInitialised = true; 
    try {
        const scriptElement = document.createElement('script');
        const script = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${gtmId}');`;
        scriptElement.innerHTML = script;
        document.head.insertBefore(scriptElement, document.head.firstChild);
    
        const noscriptElement = document.createElement('noscript');
        const noscript = `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}"
height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
        noscriptElement.innerHTML = noscript;
        document.body.insertBefore(noscriptElement, document.body.firstChild);
    } catch (error) {
        console.error('Error while injecting Google Tag Manager snippets:', error);
    }
};