export enum ERROR_CODES {
  SYSTEM_ERROR = 500,
  REGISTER_FAILED = 400,
  LOGIN_LEGACY_FAILED = 401,
  NO_LEGACY_USER_WITH_GIVEN_EMAIL_ADDRESS = 402,
  MULTIPLE_LEGACY_USERS_WITH_GIVEN_EMAIL_ADDRESS = 403,
  NO_LEGACY_DATA_ON_IDENTITY = 404,
  PASSWORD_IS_BREACHED = 405,
  NO_LEGACY_USER_WITH_GIVEN_CREDENTIALS = 406,
  MULTIPLE_LEGACY_USERS_WITH_GIVEN_CREDENTIALS = 407,
  EMAIL_TAKEN = 408,
  MIGRATION_ALREADY_DONE = 409,
  NO_GROUPS_FOUND = 410,
  ADD_LEGACY_GROUP_FAILED = 411,
  NO_EMAIL_ON_LEGACY_USER = 412,
  USER_IS_DEACTIVED = 413,
  LEGACY_LOGIN_DISABLED = 414,
}
