
import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from 'app/store';
import axios from 'axios';
import {RegisterResponseDTO} from 'features/register/registerSlice';

export interface GetMailFormState {
  email: string;
  isRegistering: boolean;
  success: boolean;
  errors: string[];
  errorCodes: number[];
  hasRegistered: boolean;
  alternatePassword: string;
}
const initialState: GetMailFormState = {
    email: '',
    isRegistering: false,
    success: true,
    errors: [],
    hasRegistered: false,
    errorCodes: [],
    alternatePassword: '',
};
export interface RegisterLegacyDto {
  username: string;
  alternatePassword?: string;
  encodedPassword: string;
  email: string;
}
export const getMailFormSlice = createSlice({
    name: 'getMailForm',
    initialState,
    reducers: {
        setAlternatePassword: (state, action: PayloadAction<string>) => {
            state.alternatePassword = action.payload;
        },
        setEmail: (state, action: PayloadAction<string>) => {
            state.email = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(registerLegacyAsync.pending, (state) => {
                state.isRegistering = true;
            })
            .addCase(registerLegacyAsync.fulfilled, (state, action) => {
                state.isRegistering = false;
                if (action.payload) {
                    state.errors = action.payload.errors;
                    state.success = action.payload.success;
                    state.hasRegistered = action.payload.success;
                    state.errorCodes = action.payload.errorCodes ?? [];
                }
            })
        ;
    },
});

export const registerLegacyAsync = createAsyncThunk<RegisterResponseDTO, RegisterLegacyDto>(
    'register/register-from-legacy',
    async (registerFormData: RegisterLegacyDto) => {
        const response = await axios.post<RegisterResponseDTO>('/janus/register/from-legacy', registerFormData);
        return response.data;
    },
);

export const selectAlternatePassword = (state: RootState) => state.getMailForm.alternatePassword;
export const selectErrors = (state: RootState) => state.getMailForm.errors;
export const selectErrorCodes = (state: RootState) => state.getMailForm.errorCodes;
export const selectSuccess = (state: RootState) => state.getMailForm.success;
export const selectIsRegistering = (state: RootState) => state.getMailForm.isRegistering;
export const selectEmail = (state: RootState) => state.getMailForm.email;
export const selectHasRegistered = (state: RootState) => state.getMailForm.hasRegistered;
export const {setEmail, setAlternatePassword} = getMailFormSlice.actions;
export default getMailFormSlice.reducer;
