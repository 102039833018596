import React, { useState } from 'react';
import { Trans } from '@lingui/react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  selectLogin,
  selectPassword,
  setLogin,
  setPassword,
  loginAsync,
  LoginFormDataDTO,
  selectLoginFailed,
  selectLoginErrors,
  selectCode,
} from './loginSlice';
import InputField from 'components/InputField';
import { t } from '@lingui/macro';
import SkeletonComponent from 'features/page/SkeletonComponent';
import { LinkDTO, PREDEFINED } from 'features/page/LinkList';
import FormWrapper from 'features/page/FormWrapper';

export const OldLogin: React.FC = () => {
  const loginFailed = useAppSelector(selectLoginFailed);
  const loginErrors = useAppSelector(selectLoginErrors);
  const login = useAppSelector(selectLogin);
  const password = useAppSelector(selectPassword);
  const errorCode = useAppSelector(selectCode);
  const dispatch = useAppDispatch();
  const [canLogin, setCanLogin] = useState(true);
  const sendLoginForm = (e?: React.MouseEvent) => {
    e?.preventDefault();
    const formData: LoginFormDataDTO = { login, password };
    dispatch(loginAsync(formData));
  };

  const links: LinkDTO[] = [
    PREDEFINED.RECOVERY,
    PREDEFINED.REGISTER,
    PREDEFINED.SUPPORT,
  ];
  const onValid = () => setCanLogin(true);
  const onInvalid = () => setCanLogin(false);
  const tokens = {
    0: <br />,
    1: <strong />,
  };
  return (
    <SkeletonComponent
      title={'login.title'}
      explanation={'login.explanation'}
      links={links}
    >
      <FormWrapper
        className={'speedmaster-login'}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onBtnClick={canLogin ? sendLoginForm : () => {}}
        okText={'Anmelden'}
      >
        <InputField
          dispatchTo={setLogin}
          selector={selectLogin}
          name="login"
          type={'text'}
          autoComplete={'email'}
          label={t`email`}
          validator={(v) =>
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(v)
          }
          focus
          onValid={onValid}
          onInvalid={onInvalid}
          errorGetter={() => t`login.email.format.invalid`}
        />
        <InputField
          dispatchTo={setPassword}
          selector={selectPassword}
          name="password"
          type="password"
          label={t`password`}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onEnter={canLogin ? sendLoginForm : () => {}}
        />
      </FormWrapper>
      {loginFailed && loginErrors.length === 0 && (
        <span className="login_failed_msg error-message">
          <Trans
            id={`login.login.request.failed`}
            components={tokens}
            values={tokens}
          />
        </span>
      )}
      {errorCode && (
        <span className="login_failed_msg error-message">
          <Trans
            id={`error.code.${errorCode}`}
            components={tokens}
            values={tokens}
          />
        </span>
      )}
    </SkeletonComponent>
  );
};

export default OldLogin;
