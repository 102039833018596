import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import { de } from "make-plural/plurals";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import { store } from "./app/store";
import { messages } from "./locales/de/messages";
import { unregister } from "./serviceWorker";

i18n.load("de", messages);
i18n.loadLocaleData({
  de: { plurals: de },
});
i18n.activate("de");
ReactDOM.render(
  <React.StrictMode>
    <I18nProvider i18n={i18n}>
      <Provider store={store}>
        <App />
      </Provider>
    </I18nProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
unregister();
