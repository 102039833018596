import React from "react";
import LinkList, { LinkDTO } from "./LinkList";
import { Trans } from "@lingui/react";

type SkeletonComponentToken = {
  title: string;
  explanation: string;
};
type SkeletonComponentPropsA = SkeletonComponentToken;

type SkeletonComponentPropsB = {
  prefix: string;
};

type SkeletonComponentBasics = {
  links: LinkDTO[];
  translationTokens?: Record<string, string>;
};
type SkeletonComponentProps = (
  | SkeletonComponentPropsA
  | SkeletonComponentPropsB
) &
  SkeletonComponentBasics;

const isTypeA = (o: unknown): o is SkeletonComponentPropsA =>
  Object.prototype.hasOwnProperty.call(o, "title");
const isTypeB = (o: unknown): o is SkeletonComponentPropsB =>
  Object.prototype.hasOwnProperty.call(o, "prefix");

const SkeletonComponent: React.FC<SkeletonComponentProps> = (props) => {
  const t = (token: keyof SkeletonComponentToken) => {
    const tokens = {
      ...props.translationTokens,
      0: <br />,
      1: <strong />,
    };
    if (isTypeA(props)) {
      const tokenName = props[token] as string;
      return <Trans id={tokenName} components={tokens} values={tokens} />;
    }
    if (isTypeB(props)) {
      return (
        <Trans
          id={`${props.prefix}.${token}`}
          components={tokens}
          values={tokens}
        />
      );
      // return i18n._(`${props.prefix}.${token}`, tokens ?? {});
    }
  };
  return (
    <div className="content login-speedmaster">
      <h2>{t("title")}</h2>
      <div className="explanation">
        <p>{t("explanation")}</p>
      </div>
      {props.children}
      {props.links.length > 0 && <LinkList links={props.links} />}
    </div>
  );
};

export default SkeletonComponent;
