import {configureStore, ThunkAction, Action} from '@reduxjs/toolkit';
import loginReducer from 'features/login/loginSlice';
import registerReducer from 'features/register/registerSlice';
import lostPasswordReducer from 'features/lostPassword/lostPasswordSlice';
import getMailFormReducer from 'features/getmail/getMailSlice';
import pageReducer from 'features/page/pageSlice';
import verificationReducer from 'features/verify/verificationSlice';
import accountReducer from 'features/account/accountSlice';

export const store = configureStore({
    reducer: {
        loginForm: loginReducer,
        registerForm: registerReducer,
        lostPassword: lostPasswordReducer,
        getMailForm: getMailFormReducer,
        page: pageReducer,
        verification: verificationReducer,
        account: accountReducer,
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
