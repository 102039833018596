import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { RootState } from "app/store";
import React, { useState } from "react";

export type InputFieldProps = {
  selector: (state: RootState) => string;
  dispatchTo: ActionCreatorWithPayload<string, string>;
  autoComplete?: string;
  name: string;
  type: "text" | "email" | "password";
  label: string;
  required?: boolean;
  validator?: (v: string) => boolean;
  errorGetter?: (v: string) => string | null;
  onValid?: () => void;
  onInvalid?: () => void;
  onEnter?: () => void;
  focus?: boolean;
  disabled?: boolean;
  validateWhileTyping?: boolean;
};
const InputField = (props: InputFieldProps) => {
  const value = useAppSelector(props.selector);
  const [isValid, setIsValid] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const onValid = () => {
    setIsValid(true);
    setError(null);
    props.onValid?.();
  };
  const onInvalid = () => {
    setIsValid(false);
    if (props.errorGetter) {
      setError(props.errorGetter(value));
    }
    props.onInvalid?.();
  };
  const required = !!props.required;
  const validator = props.validator
    ? props.validator
    : (v: string) => (required ? v.length > 0 : true);
  const validate = () => {
    if (validator(value)) {
      onValid();
    } else {
      onInvalid();
    }
  };
  const classNames = ["cl-input", "cl-input-header", "full-width"];
  classNames.push("cl-input-icon-no-icon-left");
  if (props.type === "email") {
    classNames.push("cl-input-icon-email-left");
  }
  if (!isValid && props.disabled !== true) {
    classNames.push("invalid");
  }

  return (
    <React.Fragment>
      <div className={"parameter_input_field"}>
        <div className={"cl-input-header-text"}>
          <span>
            {props.label} {required ? "*" : ""}
          </span>
        </div>
        <div className="cl-input-icon-no-icon-left">
          <input
            disabled={props.disabled}
            autoFocus={!!props.focus}
            className={classNames.join(" ")}
            type={props.type}
            value={value}
            name={props.name}
            id={props.name}
            onChange={(e) => dispatch(props.dispatchTo(e.target.value))}
            autoComplete={props.autoComplete}
            // style={{
            //     paddingLeft: '41px',
            // }}
            onBlur={validate}
            onLoad={validate}
            onKeyUp={(e) => {
              if (["Enter"].includes(e.key)) {
                props.onEnter?.();
              }
              if (props.validateWhileTyping) {
                validate();
              }
            }}
          ></input>
        </div>
        {error && (
          <span
            style={{
              fontSize: "10px",
              color: "#ff0000",
            }}
          >
            {error}
          </span>
        )}
      </div>
    </React.Fragment>
  );
};

export default InputField;
