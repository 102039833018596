import SkeletonComponent from "features/page/SkeletonComponent";
import React from "react";

const Support = () => {
  return (
    <React.Fragment>
      <SkeletonComponent prefix="support" links={[]}>
        <p className="support">
          Melde dich einfach telefonisch oder per Mail bei unserem
          Kundenservice:
          <br />
          Telefon Österreich: <a href="tel:+43724129429">
            +43 (0) 7241 29429
          </a>{" "}
          <br />
          Telefon Deutschland:{" "}
          <a href="tel:+4998439804300">+49 (0) 9843 980430 0</a> <br />
          E-Mail:{" "}
          <a
            href="mailto:office@speedmaster.at"
            target="_blank"
            rel="noopener noreferrer"
          >
            office@speedmaster.at
          </a>
        </p>
        <h2>Mit einem Klick sind wir bei dir!</h2>
        <p className="support">
          Über TeamViewer können wir uns gemeinsam dein Problem direkt ansehen.
        </p>

        <div className="support cl-button">
          <a
            className="cl-button"
            href="https://www.speedmaster.at/wp-content/uploads/TeamViewerSpeedMaster.exe"
          >
            Fernwartung Speedmaster
          </a>
        </div>
      </SkeletonComponent>
    </React.Fragment>
  );
};
export default Support;
