import React, { useEffect } from 'react';
import { ConfigContext } from './ConfigContext';

interface ConfigFile {
  login: 'generic' | 'legacy';
  gtmId?: string;
}

export const ConfigProvider: React.FC = ({ children }) => {
  const [config, setConfig] = React.useState<ConfigFile | undefined>();
  const [loading, setLoading] = React.useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    fetch('/config.json')
      .then((res) => res.json())
      .then((config) => setConfig(config))
      .catch(() => setConfig({ login: 'legacy', gtmId: '' }))
      .finally(() => setLoading(false));
  }, []);

  return (
    <ConfigContext.Provider
      value={{ 
        legacy: config?.login !== 'generic', 
        gtmId: config?.gtmId || '', 
        loading
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};
