import React from 'react';
import {Link} from 'react-router-dom';
import {t} from '@lingui/macro';
import {i18n} from '@lingui/core';

export type LinkDTO = {
  text: string,
  target: string,
  isHref: boolean,
};
type LinkListProps = {
  links: LinkDTO[],
};

export const PREDEFINED:Record<string, LinkDTO> = {
    SUPPORT: {
        text: `links.support.text`,
        target: '/support',
        isHref: false,
    },
    REGISTER: {
        text: t`links.register.text`,
        target: '/register',
        isHref: false,
    },
    RECOVERY: {
        text: t`links.recovery.text`,
        target: '/recovery',
        isHref: false,
    },
    LOGIN_LEGACY: {
        text: t`links.login.legacy.text_2`,
        target: '/login_legacy',
        isHref: false,
    },
    LOGIN_ALTERNATE_TEXT: {
        text: t`links.login.legacy.alternate_text`,
        target: '/',
        isHref: false,
    },
    LOGIN_ALTERNATE_TEXT_2: {
        text: t`links.login.legacy.alternate_text_2`,
        target: '/',
        isHref: false,
    },
    LOGIN: {
        text: t`links.login.legacy.text`,
        target: '/',
        isHref: false,
    },
    LOGOUT_BACKLINK: {
        text: t`links.logout.back`,
        target: '/janus/login/logout', 
        isHref: true,
    },
};

const LinkList:React.FC<LinkListProps> = (props) => {
    return (
        <div className="link_list">
            <p>
                {props.links.map((l) => {
                    if (l.isHref) {
                        return <a key={l.target} href={l.target}>{i18n._(l.text)}</a>;
                    }
                    return <Link key={l.target} to={l.target}>{i18n._(l.text)}</Link>;
                })}
            </p>
        </div>
    );
};
export default LinkList;
