import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export interface PageState {
  showBanner: boolean;
}
const initialState: PageState = {
  showBanner: true,
};
export const pageSlice = createSlice({
  name: "page",
  initialState,
  reducers: {
    setBanner: (state, action: PayloadAction<boolean>) => {
      state.showBanner = action.payload;
    },
  },
});

export const selectShowBanner = (state: RootState) => state.page.showBanner;
export const { setBanner } = pageSlice.actions;
export default pageSlice.reducer;
