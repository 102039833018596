import React, { useCallback, useState } from 'react';
import { Trans } from '@lingui/react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import InputField from 'components/InputField';
import {
  selectLogin,
  selectPassword,
  setLogin,
  setPassword,
  loginAsync,
  LoginFormDataDTO,
  selectLoginFailed,
  selectLoginErrors,
  selectCode,
} from './loginSlice';
import { t } from '@lingui/macro';
import { PREDEFINED } from 'features/page/LinkList';

import SmoodoLogo from './../../img/login-speedmaster/smoodo-logo.png';
// import HeaderLogo from './../../img/login-speedmaster/header_logo.png';
import PoweredBy from './../../img/login-speedmaster/powered-by.png';

import './GenericLogin.css';

export const GenericLogin: React.FC = () => {
  const dispatch = useAppDispatch();
  const login = useAppSelector(selectLogin);
  const password = useAppSelector(selectPassword);
  const loginFailed = useAppSelector(selectLoginFailed);
  const loginErrors = useAppSelector(selectLoginErrors);
  const errorCode = useAppSelector(selectCode);
  const navigate = useNavigate();

  const tokens = {
    0: <br />,
    1: <strong />,
  };

  const [canLogin, setCanLogin] = useState(true);
  const [visiblePass, setVisiblePass] = useState(false);
  const [remember, setRemember] = useState(false);

  const sendLoginForm = () => {
    const formData: LoginFormDataDTO = { login, password };
    dispatch(loginAsync(formData));
  };
  const onValid = () => setCanLogin(true);
  const onInvalid = () => setCanLogin(false);

  const onCheckboxChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.target;
      setRemember(checked);
    },
    []
  );

  const navigateTo = useCallback((link: string) => {
    navigate(link);
  }, []);

  return (
    <div className="loginWrapper">
      <div className="imageWrapper">
        <img src={SmoodoLogo} alt="Smoodo logo" className="topLeft" />
        {/* <img src={HeaderLogo} alt="Hackl logo" className="bottomLeft" /> */}
        <img src={PoweredBy} alt="Powered by" className="bottomRight" />
      </div>
      <div className="formWrapper">
        <div className="loginTitle">{t`login`}</div>
        <div className="loginDesc">{t`login.subtitle.text`}</div>
        <form>
          <InputField
            dispatchTo={setLogin}
            selector={selectLogin}
            name="login"
            type={'text'}
            autoComplete={'email'}
            label={t`email`}
            validator={(v) =>
              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(v)
            }
            focus
            onValid={onValid}
            onInvalid={onInvalid}
            errorGetter={() => t`login.email.format.invalid`}
          />
          <div className="passwordWrapper">
            <InputField
              dispatchTo={setPassword}
              selector={selectPassword}
              name="password"
              type={visiblePass ? 'text' : 'password'}
              label={t`password`}
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              onEnter={canLogin ? sendLoginForm : () => {}}
            />
            <button
              type="button"
              className={`icon ${visiblePass ? 'eyeSlash' : 'eye'}`}
              onClick={() => setVisiblePass(!visiblePass)}
            />
          </div>
          <div className="actionsWrapper">
            <div className="checkboxWrapper">
              <input
                type="checkbox"
                id="remember"
                checked={remember}
                onChange={onCheckboxChange}
              />
              <label htmlFor="remember">{t`login.remember.text`}</label>
            </div>
            <div
              className="forgotPass"
              onClick={() => navigateTo(PREDEFINED.RECOVERY.target)}
            >
              {t`login.forgot.password.text`}
            </div>
          </div>
          <button
            className="submitButton"
            type="button"
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onClick={canLogin ? sendLoginForm : () => {}}
          >
            {t`login`}
          </button>
          <div className="registerAccount">
            {t`login.no.account.text`}
            <span onClick={() => navigateTo(PREDEFINED.REGISTER.target)}>
              {t`register`}
            </span>
          </div>
        </form>

        {loginFailed && loginErrors.length === 0 && (
          <span className="login_failed_msg error-message">
            <Trans
              id={`login.login.request.failed`}
              components={tokens}
              values={tokens}
            />
          </span>
        )}

        {errorCode && (
          <span className="login_failed_msg error-message">
            <Trans
              id={`error.code.${errorCode}`}
              components={tokens}
              values={tokens}
            />
          </span>
        )}
      </div>
    </div>
  );
};
