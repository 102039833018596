import React from "react";
import { GenericLogin } from "./GenericLogin";
import { CustomLogin } from "./CustomLogin";

export interface LoginProps {
  generic?: boolean;
}

const Login: React.FC<LoginProps> = ({ generic = true }) => {
  return generic ? <GenericLogin /> : <CustomLogin />;
};

export default Login;
