import React from 'react';

const Footer = () => {
  return (
    <footer>
      <address>
        <ul>
          <li>
            <b>Speedmaster GmbH</b>
          </li>
          <li>Wipfing 41, A-4653 Eberstalzell</li>
          <li>Telefon: +43 7241 29429 0</li>
          <li>
            E-Mail:
            <a href="mailto:%6Fff%69ce%40s%70%65e%64mast%65r.%61t">
              office@speedmaster.at
            </a>
          </li>
          <li>
            Web: <a href="http://www.speedmaster.at">www.speedmaster.at</a>
          </li>
        </ul>
      </address>

      <address>
        <ul>
          <li>
            <b>Speedmaster GmbH</b>
          </li>
          <li>Speedmasterstraße 1, D-91628 Steinsfeld</li>
          <li>Telefon: +49 9843 980430 0</li>
          <li>
            E-Mail:
            <a href="mailto:%6Fff%69ce%40s%70%65e%64mast%65r.%64e">
              office@speedmaster.de
            </a>
          </li>
          <li>
            Web: <a href="http://www.speedmaster.de">www.speedmaster.de</a>
          </li>
        </ul>
      </address>
    </footer>
  );
};

export default Footer;
