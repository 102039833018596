import React from "react";

type FormWrapperPropsA = {
  className: string;
  onBtnClick: () => void;
  pre?: React.ReactNode;
  hideBtn?: false;
  okText?: string;
};

type FormWrapperPropsB = {
  className: string;
  pre?: React.ReactNode;
  hideBtn: true;
  okText?: string;
};

type FormWrapperProps = FormWrapperPropsA | FormWrapperPropsB;

const FormWrapper: React.FC<FormWrapperProps> = (props) => (
  <>
    {props.pre}
    <form className={props.className + " form-component"}>
      {props.children}
      {props.hideBtn !== true && (
        <div
          tabIndex={0}
          className={`cl-button cl-button-animated `}
          onClick={props.onBtnClick}
          onKeyPress={(e) => {
            if ([" ", "Enter"].includes(e.key)) {
              props.onBtnClick();
            }
          }}
        >
          <div>{props.okText ? props.okText : "\u2192"}</div>
        </div>
      )}
    </form>
  </>
);

export default FormWrapper;
