import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import axios from "axios";
import {
  initBrowserVerification,
  InitBrowserVerificationResult,
} from "../../lib/kratos";

const baseHost = window.location.host
  .split(/\./)
  .reverse()
  .slice(0, -1)
  .concat("id")
  .reverse()
  .join(".");
const config = {
  baseUrl: `https://${baseHost}`,
  baseAdminUrl: `https://${baseHost}`,
};

export type VerificationData = {
  flowId: string;
  token: string;
};
export interface VerificationState {
  isPending: boolean;
  success: boolean;
  hasRun: boolean;
  errors: string[];
}
const initialState: VerificationState = {
  isPending: false,
  success: false,
  hasRun: false,
  errors: [],
};
export const verificationSlice = createSlice({
  name: "verification",
  initialState,
  reducers: {
    setBanner: (state, action: PayloadAction<boolean>) => {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(initFlowAsync.pending, (state) => {
        state.isPending = true;
      })
      .addCase(initFlowAsync.fulfilled, (state, action) => {
        state.isPending = false;
      })
      .addCase(finishFlowAsync.pending, (state) => {
        state.isPending = true;
      })
      .addCase(finishFlowAsync.fulfilled, (state, action) => {
        state.isPending = false;
        state.hasRun = true;
        state.success = !(action.payload === false);
      });
  },
});

export const initFlowAsync = createAsyncThunk<InitBrowserVerificationResult>(
  "verification/init",
  async () => {
    return initBrowserVerification(config);
  }
);
export const finishFlowAsync = createAsyncThunk<
  Record<string, unknown> | false,
  VerificationData
>("verification/finish", async (data: VerificationData) => {
  const url = `${config.baseUrl}/self-service/verification?flow=${data.flowId}&token=${data.token}`;
  return axios.get(url).then((r) => {
    if (r.status > 399) {
      // catch all errors
      return false;
    }
    return r.data as VerificationData;
    // }).catch((e) => {
    //     return false;
  });
  // return MrOry.finishBrowserVerification(config)(data.flowId, data.token);
});
export const selectIsPending = (state: RootState) =>
  state.verification.isPending;
export const selectHasRun = (state: RootState) => state.verification.hasRun;
export const selectSuccess = (state: RootState) => state.verification.success;
export const { setBanner } = verificationSlice.actions;
export default verificationSlice.reducer;
