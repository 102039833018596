import { useAppDispatch, useAppSelector } from "app/hooks";
import InputField from "components/InputField";
import FormWrapper from "features/page/FormWrapper";
import SkeletonComponent from "features/page/SkeletonComponent";
import React from "react";
import {
  selectLogin,
  selectPassword as selectOriginalPassword,
} from "features/login/loginSlice";
import {
  selectPasswordRepeat,
  selectPassword,
  setPassword,
  setPasswordRepeat,
} from "./registerSlice";
import { t } from "@lingui/macro";
import { Trans as TransReact } from "@lingui/react";
import {
  registerLegacyAsync,
  selectErrors,
  selectHasRegistered,
  selectSuccess,
} from "features/getmail/getMailSlice";

const FromLegacy = () => {
  const login = useAppSelector(selectLogin);
  const originalPassword = useAppSelector(selectOriginalPassword);
  const password = useAppSelector(selectPassword);
  const passwordRepeat = useAppSelector(selectPasswordRepeat);
  const dispatch = useAppDispatch();
  const errors = useAppSelector(selectErrors);
  const success = useAppSelector(selectSuccess);
  const hasRegistered = useAppSelector(selectHasRegistered);
  if (success && hasRegistered) {
    window.location.href =
      window.location.href.replace(window.location.pathname, "") +
      "/janus/login/logout";
  }

  const passwordsEqual = () => {
    return password === passwordRepeat;
  };
  const getErrors = () => {
    if (errors.length > 0) {
      return (
        <>
          {errors.map((e, i) => (
            <span key={`error_${i}`} className="error-message">
              <TransReact id={e} />
            </span>
          ))}
        </>
      );
    }

    if (!passwordsEqual()) {
      return <span className="error-message">{t`passwords.not.equal`}</span>;
    } else {
      return <span />;
    }
  };
  return (
    <SkeletonComponent prefix="fromLegacy" links={[]}>
      <FormWrapper
        className=""
        okText={"Abschicken"}
        onBtnClick={() => {
          if (passwordsEqual()) {
            dispatch(
              registerLegacyAsync({
                email: "::from_legacy",
                username: login,
                encodedPassword: btoa(originalPassword),
                alternatePassword: password,
              })
            );
          }
        }}
      >
        <InputField
          name="password"
          type="password"
          label="neues Passwort"
          selector={selectPassword}
          dispatchTo={setPassword}
        />
        <InputField
          name="password-repeat"
          type="password"
          label="Passwort erneut eingeben"
          selector={selectPasswordRepeat}
          dispatchTo={setPasswordRepeat}
        />
      </FormWrapper>
      {getErrors()}
    </SkeletonComponent>
  );
};

export default FromLegacy;
