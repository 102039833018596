import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import axios from "axios";

export interface LoginFormState {
  code: number;
  login: string;
  password: string;
  isLoggingIn: boolean;
  loginFailed: boolean;
  loginResult: {
    legacy: boolean;
    kratos: boolean;
    redirectionTarget:
      | "legacy"
      | "channel"
      | "_no_account"
      | "_inactive"
      | ""
      | "legacy_inform"
      | "legacy_inform_pw"
      | "legacy_force";
    email: string;
    success: boolean;
  };
  isUsingEmail: boolean;
  errors: string[];
  errorCode?: number;
  groups: string[];
  legacyState?: { code: number; message: string };
}
const initialState: LoginFormState = {
  code: 200,
  login: "",
  password: "",
  isLoggingIn: false,
  loginFailed: false,
  loginResult: {
    legacy: false,
    kratos: false,
    redirectionTarget: "",
    email: "",
    success: false,
  },
  isUsingEmail: false,
  errors: [],
  groups: [],
};
export const loginFormSlice = createSlice({
  name: "loginForm",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setPassword: (state, action: PayloadAction<string>) => {
      state.password = action.payload;
    },
    setLogin: (state, action: PayloadAction<string>) => {
      state.login = action.payload;
    },
    toggleLoggingIn: (state) => {
      state.isLoggingIn = !state.isLoggingIn;
    },
    startLogin: (state) => {
      state.isLoggingIn = true;
    },
    stopLogin: (state) => {
      state.isLoggingIn = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state) => {
        state.isLoggingIn = true;
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        state.isLoggingIn = false;
        state.loginFailed = !action.payload.success;
        state.loginResult.success = action.payload.success;
        if (action.payload) {
          if (!action.payload.success) {
            state.code = action.payload.code;
            state.errors = [action.payload.reason];
            state.errorCode = action.payload.code;
            state.loginResult.email =
              action.payload.mail === "unknown" ? "" : action.payload.mail;
          } else {
            state.groups = action.payload.groups;
            state.legacyState = action.payload.legacyState;
          }
        }
      })
      .addCase(checkLoginAsync.pending, (state) => {
        state.isLoggingIn = true;
      })
      .addCase(checkLoginAsync.fulfilled, (state, action) => {
        state.isLoggingIn = false;
        if (action.payload) {
          state.loginResult = {
            legacy: action.payload.legacy.isLoggedIn,
            kratos: action.payload.kratos.isLoggedIn,
            success:
              action.payload.legacy.isLoggedIn ||
              action.payload.kratos.isLoggedIn,
            redirectionTarget: action.payload.redirectionTarget,
            email: action.payload.email,
          };
          state.groups = action.payload.kratos.groups ?? [];
        }
      });
  },
});

export type LoginFormDataDTO = {
  login: string;
  password: string;
  exclude?: "legacy" | "kratos";
};
type LoginResponseV2DTO_success = {
  success: true;
  groups: string[];
  legacyState?: { code: number; message: string };
};
type LoginResponseV2DTO_fail = {
  success: false;
  reason: string;
  mail: string;
  code: number;
};
export type LoginResponseV2DTO =
  | LoginResponseV2DTO_fail
  | LoginResponseV2DTO_success;

export type LoginResponseDTO = {
  legacy: {
    isLoggedIn: boolean;
    sessionCookie: string; // FIXME
    user: string; // FIXME
  };
  kratos: {
    isLoggedIn: boolean;
    sessionCookie: string; // FIXME
    user: string; // FIXME
    groups?: string[];
  };
  redirectionTarget: "legacy" | "channel" | "_no_account" | "_inactive";
  email: string;
  errors?: string[];
};
export const loginAsync = createAsyncThunk<
  LoginResponseV2DTO,
  LoginFormDataDTO
>("login/login", async (loginFormData: LoginFormDataDTO) => {
  const response = await axios.post<LoginResponseV2DTO>(
    "/janus/login/combined",
    loginFormData
  );
  return response.data;
});
export const checkLoginAsync = createAsyncThunk<LoginResponseDTO, void>(
  "login/checkLogin",
  async () => {
    return await axios
      .get<LoginResponseDTO>("/janus/login/check", { withCredentials: true })
      .then((response) => response.data);
  }
);

export const selectLogin = (state: RootState) => state.loginForm.login;
export const selectLoginFailed = (state: RootState) =>
  state.loginForm.loginFailed;
export const selectLoginErrors = (state: RootState) => state.loginForm.errors;
export const selectPassword = (state: RootState) => state.loginForm.password;
export const selectIsLoading = (state: RootState) =>
  state.loginForm.isLoggingIn;
export const selectIsLoggedIn = (state: RootState) =>
  state.loginForm.loginResult;
export const selectIsUsingEmail = (state: RootState) =>
  state.loginForm.isUsingEmail;
export const selectLegacyMail = (state: RootState) =>
  state.loginForm.loginResult.email;
export const selectGroups = (state: RootState) => state.loginForm.groups;
export const selectLegacyState = (state: RootState) =>
  state.loginForm.legacyState;
export const selectCode = (state: RootState) => state.loginForm.errorCode;
export const selectLoginResult = (state: RootState) =>
  state.loginForm.loginResult;
export const { setLogin, setPassword } = loginFormSlice.actions;
export default loginFormSlice.reducer;
