import { useAppDispatch, useAppSelector } from "app/hooks";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  selectGroups,
  selectCode,
  selectLoginResult,
  checkLoginAsync,
  selectLegacyState,
} from "./loginSlice";
import { isDebugging } from "lib/debug";
import { ERROR_CODES } from "lib/errorcodes";

export const getRedirectionTarget = (system: "legacy" | "channel") => {
  let finalSystem = system as string;
  if (system === "channel") {
    finalSystem = "shop";
  }
  return (
    window.location.protocol +
    "//" +
    window.location.host
      .split(/\./)
      .reverse()
      .slice(0, -1)
      .concat(finalSystem)
      .reverse()
      .join(".")
  );
};
const redirect = (isDebugging: boolean, target: string) => {
  if (isDebugging) {
    // eslint-disable-next-line
    console.log(`Redirecting you to ${target} in 1500ms`);
    setTimeout(() => {
      // eslint-disable-next-line
      console.log("Redirecting");
      window.location.href = target;
    }, 1500);
  } else {
    window.location.href = target;
  }
};
const errorCodesForMigration = [
  ERROR_CODES.PASSWORD_IS_BREACHED,
  ERROR_CODES.EMAIL_TAKEN,
  ERROR_CODES.NO_EMAIL_ON_LEGACY_USER,
];
const getRedirectURL = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  try {
    return decodeURIComponent(urlParams.get("redirect_url") || "");
  } catch (e) {
    return "";
  }
};
const CheckLogin = () => {
  const dispatch = useAppDispatch();
  const groups = useAppSelector(selectGroups);
  const legacyState = useAppSelector(selectLegacyState);
  const errorCode = useAppSelector(selectCode);
  const loginResult = useAppSelector(selectLoginResult);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(checkLoginAsync());
  }, [dispatch]);
  useEffect(() => {
    const whitelist = ["/legacy-pw", "/verify", "/account"];
    for (const p of whitelist) {
      if (window.location.pathname.endsWith(p)) {
        return;
      }
    }
    if (loginResult.success !== true) {
      if (errorCodesForMigration.includes(errorCode ?? -1)) {
        navigate("/legacy");
        return;
      }
    }
    if (errorCode === ERROR_CODES.NO_GROUPS_FOUND) {
      navigate("/waiting-for-approval");
      return;
    }

    // if redirect url is set and starts with redirection for legacy
    // and legacy failed or is disabled, redirect to legacy-not-avaliable
    if (
      [
        ERROR_CODES.LOGIN_LEGACY_FAILED,
        ERROR_CODES.LEGACY_LOGIN_DISABLED,
      ].includes(legacyState?.code ?? -1) &&
      getRedirectURL().startsWith(getRedirectionTarget("legacy"))
    ) {
      navigate("/legacy-not-avaliable");
      return;
    }

    if (groups.includes("F - Kunden")) {
      if (getRedirectURL() !== "") {
        redirect(isDebugging(), getRedirectURL());
      } else {
        redirect(isDebugging(), getRedirectionTarget("channel"));
      }
    } else if (groups.includes("G - Altshop")) {
      redirect(isDebugging(), getRedirectionTarget("legacy"));
    }
  }, [dispatch, groups, errorCode, loginResult, navigate]);
  return <div className="debug_only" />;
};

export default CheckLogin;
