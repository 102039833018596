import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Header = () => {
  const { pathname } = useLocation();

  return (
    <>
      <header>
        <Link className="logo" to="/">
          Speedmaster
        </Link>
      </header>
      {pathname !== '/register' && (
        <div className="wrapper">
          <div>
            <div className={'img'} />
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
