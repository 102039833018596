import SkeletonComponent from "features/page/SkeletonComponent";
import React from "react";
import { t } from "@lingui/macro";

type VerificationSuccessProps = Record<string, unknown>;

const VerificationSuccess: React.FC<VerificationSuccessProps> = () => {
  return (
    <SkeletonComponent prefix="verify" links={[]}>
      <div className="link_list">
        <p>
          <a href="/">{t`links.login.legacy.alternate_text_2`}</a>
        </p>
      </div>
    </SkeletonComponent>
  );
};

export default VerificationSuccess;
