import React from "react";
import { Trans } from "@lingui/react";

const Waiting = () => {
  const tokens = {
    0: <br />,
    1: <strong />,
  };
  return (
    <p>
      <Trans id={"register.content"} components={tokens} values={tokens} />
    </p>
  );
};

export default Waiting;
