import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from 'app/store';
import axios from 'axios';

export interface LostPasswordState {
  csrf: string;
  password: string;
  isRecovering: boolean;
  recoveryFailed: boolean;
  flowId: string;
  errors: string[];
  recoverySucceeded: boolean;
  recovery: {
    password: string,
    repeat: string,
  };
}
const initialState: LostPasswordState = {
    csrf: '',
    password: '',
    isRecovering: false,
    recoveryFailed: false,
    recoverySucceeded: false,
    flowId: '',
    errors: [],
    recovery: {
        password: '',
        repeat: '',
    },
};
export type LostPasswordData = {
  csrf: string,
  flowId: string,
  email: string,
};
export type LostPasswordResponse = {
  success: true,
}
export const lostPasswordSlice = createSlice({
    name: 'lastPassword',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
        setPassword: (state, action: PayloadAction<string>) => {
            state.password = action.payload;
        },
        setLogin: (state, action: PayloadAction<string>) => {
            state.csrf = action.payload;
        },
        setRecoveryPasswordRepeat: (state, action: PayloadAction<string>) => {
            state.recovery.repeat = action.payload;
        },
        setRecoveryPassword: (state, action: PayloadAction<string>) => {
            state.recovery.password = action.payload;
        },
        toggleLoggingIn: (state) => {
            state.isRecovering = !state.isRecovering;
        },
        startLogin: (state) => {
            state.isRecovering = true;
        },
        stopLogin: (state) => {
            state.isRecovering = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(flowAsync.pending, (state) => {
                state.isRecovering = true;
            })
            .addCase(flowAsync.fulfilled, (state, action) => {
                state.isRecovering = false;
                if (!action || !action.payload) {
                    state.recoveryFailed = true;
                    state.errors = ['No error received'];
                    return;
                }
                state.recoveryFailed = !action.payload.success;
                // state.errors = action.payload.errors;
                state.recoverySucceeded = action.payload.success;
            })
        ;
    },
});

export const flowAsync = createAsyncThunk<LostPasswordResponse, string>(
    'lostPassword/lostPassword',
    async (login:string) => {
        const response = await axios.post('/janus/login/forgotPassword', {login});
        return {
            success: response.status === 200,
        } as LostPasswordResponse;
    },
);
export type PWFlowProperties = {
  password: string,
  flow: string,
  csrf: string,
};
export const sendPWFlowAsync = createAsyncThunk<LostPasswordResponse, PWFlowProperties>(
    'lostPassword/submitNewPassword',
    async (data:PWFlowProperties) => {
        const response = await axios.post('/janus/login/setPassword', data);
        return {
            success: response.status === 200,
        } as LostPasswordResponse;
    },
);

export const selectRecoveryFailed = (state: RootState) => state.lostPassword.recoveryFailed;
export const selectRecoverySucceeded = (state: RootState) => state.lostPassword.recoverySucceeded;

export const selectRecoveryPassword = (state: RootState) => state.lostPassword.recovery.password;
export const selectRecoveryPasswordRepeat = (state: RootState) => state.lostPassword.recovery.repeat;


export const {setLogin, setPassword, setRecoveryPassword, setRecoveryPasswordRepeat} = lostPasswordSlice.actions;
export default lostPasswordSlice.reducer;
