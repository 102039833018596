import { t } from "@lingui/macro";
import { Trans as TransReact } from "@lingui/react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import InputField from "components/InputField";
import { PREDEFINED } from "features/page/LinkList";
import SkeletonComponent from "features/page/SkeletonComponent";
import React from "react";
import {
  registerAsync,
  RegisterFormDataDTO,
  selectCity,
  selectCompanyName,
  selectCountryCode,
  selectEmail,
  selectErrors,
  selectFax,
  selectFirstName,
  selectInvoiceEmail,
  selectIsRegistering,
  selectLastName,
  selectMobile,
  selectNewsletter,
  selectPassword,
  selectPasswordRepeat,
  selectPhone,
  selectPostal,
  selectStreet,
  selectStreetNumber,
  selectSuccess,
  selectUstId,
  selectWebsite,
  setCity,
  setCompanyName,
  setCountryCode,
  setEmail,
  setFirstName,
  setInvoiceEmail,
  setLastName,
  setMobile,
  setNewsletter,
  setPassword,
  setPasswordRepeat,
  setPhone,
  setPostal,
  setStreet,
  setStreetNumber,
  setUstId,
  setWebsite,
} from "./registerSlice";

const Register = () => {
  const ustId = useAppSelector(selectUstId);
  const companyName = useAppSelector(selectCompanyName);
  const firstName = useAppSelector(selectFirstName);
  const lastName = useAppSelector(selectLastName);
  const street = useAppSelector(selectStreet);
  const streetNumber = useAppSelector(selectStreetNumber);
  const email = useAppSelector(selectEmail);
  const postal = useAppSelector(selectPostal);
  const fax = useAppSelector(selectFax);
  const invoiceEmail = useAppSelector(selectInvoiceEmail);
  const city = useAppSelector(selectCity);
  const phone = useAppSelector(selectPhone);
  const mobile = useAppSelector(selectMobile);
  const website = useAppSelector(selectWebsite);
  const country = useAppSelector(selectCountryCode);
  const getMails = useAppSelector(selectNewsletter);
  const password = useAppSelector(selectPassword);
  const passwordRepeat = useAppSelector(selectPasswordRepeat);

  const formValid =
    companyName.length > 0 &&
    firstName.length > 0 &&
    lastName.length > 0 &&
    street.length > 0 &&
    streetNumber.length > 0 &&
    postal.length > 0 &&
    city.length > 0 &&
    phone.length > 0 &&
    email.length > 0 &&
    password.length > 0 &&
    passwordRepeat.length > 0 &&
    password === passwordRepeat;
  const success = useAppSelector(selectSuccess);
  const errors = useAppSelector(selectErrors);
  const dispatch = useAppDispatch();
  const isRegistering = useAppSelector(selectIsRegistering);
  const getErrors = () => {
    if (errors.length > 0) {
      return (
        <div className="form_errors">
          {t`register.failed.explanation`}
          <ul>
            {errors.map((e, i) => (
              <li key={`error_${i}`} className="error-message">
                <TransReact id={e} />
              </li>
            ))}
          </ul>
        </div>
      );
    } else {
      return <span />;
    }
  };
  const sendRegisterForm = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    const formData: RegisterFormDataDTO = {
      companyName,
      contact: {
        lastName,
        firstName,
      },
      address: {
        street,
        streetNumber,
        email,
        postal,
        fax,
        invoiceEmail,
        city,
        phone,
        mobile,
        website,
        countryCode: country,
        newsletter: getMails,
        ustIdNumber: ustId,
      },
      password,
    };
    dispatch(registerAsync(formData));
  };
  if (isRegistering) {
    return <span>{t`register.loading.text`}</span>;
  }
  let content = (
    <div className="registration">
      {getErrors()}
      <form>
        <fieldset>
          <legend className="custom-legend">
            01 – Information zum Betrieb
          </legend>
          <InputField
            dispatchTo={setUstId}
            selector={selectUstId}
            name="ust_id"
            type="text"
            label="USt-IdNr. UID-Nr./ Mwst.Nr."
          />
        </fieldset>
        <fieldset>
          <InputField
            dispatchTo={setCompanyName}
            selector={selectCompanyName}
            name="company_name"
            type="text"
            label="Firmenwortlaut"
            required
          />
        </fieldset>
        <fieldset>
          <legend className="custom-legend">02 – Ansprechpartner</legend>
          <InputField
            dispatchTo={setFirstName}
            selector={selectFirstName}
            name="first_name"
            type="text"
            label="Vorname"
            required
          />
          <InputField
            dispatchTo={setLastName}
            selector={selectLastName}
            name="last_name"
            type="text"
            label="Name"
            required
          />
        </fieldset>
        <fieldset>
          <legend className="custom-legend">03 – Adresse</legend>
          <InputField
            dispatchTo={setStreet}
            selector={selectStreet}
            name="strasse"
            type="text"
            label="Straße"
            required
          />
          <InputField
            dispatchTo={setStreetNumber}
            selector={selectStreetNumber}
            name="hausnummer"
            type="text"
            label="Hausnummer"
            required
          />
          <InputField
            dispatchTo={setPostal}
            selector={selectPostal}
            name="postleitzahl"
            type="text"
            label="PLZ"
            required
          />
          <InputField
            dispatchTo={setCity}
            selector={selectCity}
            name="stadt"
            type="text"
            label="Stadt"
            required
          />
          <br />
          <select
            name="Land"
            id="Land"
            onChange={(e) =>
              dispatch(
                setCountryCode(e.target.options[e.target.selectedIndex].value)
              )
            }
            defaultValue={country}
          >
            <option value="DE">Deutschland</option>
            <option value="AT">Österreich</option>
            <option value="CH">Schweiz</option>
            <option value="IT">Italien</option>
            <option value="other">anderes</option>
          </select>
          <label htmlFor="Land">Land</label>
          <br />
          <InputField
            dispatchTo={setPhone}
            selector={selectPhone}
            name="telefonnummer"
            type="text"
            label="Telefonnummer"
            required
          />
          <InputField
            dispatchTo={setMobile}
            selector={selectMobile}
            name="handynummer"
            type="text"
            label="Mobiltelefonnummer"
          />
          <InputField
            dispatchTo={setEmail}
            selector={selectEmail}
            name="email"
            autoComplete="email"
            type="email"
            required
            label="Email-Adresse"
          />
          <InputField
            dispatchTo={setInvoiceEmail}
            selector={selectInvoiceEmail}
            name="invoiceEmail"
            autoComplete="email"
            type="email"
            label="abweichende Email für Rechnung (optional)"
          />
          <InputField
            dispatchTo={setWebsite}
            selector={selectWebsite}
            name="website"
            type="text"
            label="Website"
          />

          <input
            type="checkbox"
            name="abmeldung_infomail"
            id="abmeldung_infomail"
            checked={!getMails}
            onChange={(e) => {
              dispatch(setNewsletter(!e.target.checked));
            }}
          />
          <label htmlFor="abmeldung_infomail">Abmeldung Infomail</label>
        </fieldset>
        <fieldset>
          <InputField
            dispatchTo={setPassword}
            selector={selectPassword}
            name="passwort"
            autoComplete="current-password"
            type="password"
            label="Passwort"
          />
          <InputField
            dispatchTo={setPasswordRepeat}
            selector={selectPasswordRepeat}
            name="passwort_repeat"
            autoComplete="current-password"
            type="password"
            label="Passwort erneut eingeben"
          />
        </fieldset>
        <button
          className="cl-button"
          disabled={!formValid}
          title={
            !formValid
              ? "Es wurden noch nicht alle Pflichtfelder ausgefüllt"
              : "Hier klicken und registrieren!"
          }
          onClick={sendRegisterForm}
        >
          Jetzt registrieren
        </button>
      </form>
    </div>
  );
  if (success) {
    content = <span />;
  }
  return (
    <SkeletonComponent
      title={"register.title"}
      explanation={
        success ? "register.explanation.success" : "register.explanation"
      }
      links={
        success ? [] : [PREDEFINED.SUPPORT, PREDEFINED.LOGIN_ALTERNATE_TEXT]
      }
    >
      {content}
    </SkeletonComponent>
  );
};
export default Register;
