import React, { useMemo } from "react";
import { t } from "@lingui/macro";
import { isDebugging } from "lib/debug";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Login from "./features/login/Login";
import Register from "features/register/Register";
import GetMail from "features/getmail/GetMail";
import CheckLogin from "features/login/CheckLogin";
import LostPassword from "features/lostPassword/LostPassword";
import Footer from "features/page/Footer";
import Header from "features/page/Header";
import Waiting from "features/waiting/Waiting";
import Support from "features/support/Support";
import VerificationSuccess from "features/verify/VerificationSuccess";
import Verify from "features/verify/Verify";
import FromLegacy from "features/register/FromLegacy";
import Account from "features/account/Account";
import { ConfigProvider, useConfig } from "./components/provider";
import OldLogin from "./features/login/OldLogin";
import LegacyMaintenance from "features/waiting/LegacyMaintenance";
import { injectGTMSnippets } from "helper/GoogleTagManager";

const Main: React.FC = () => {
  const { legacy, loading, gtmId } = useConfig();

  const classname = useMemo(() => (legacy ? "main" : ""), [legacy]);
  const header = useMemo(() => (legacy ? <Header /> : null), [legacy]);
  const footer = useMemo(() => (legacy ? <Footer /> : null), [legacy]);
  const login = useMemo(
    () => (legacy ? <OldLogin /> : <Login generic />),
    [legacy]
  );
  const title = useMemo(
    () => (legacy ? <h1>{t`application.title`}</h1> : null),
    [legacy]
  );

  // Google Tag Manager Script injection
  React.useEffect(() => {
    if (!loading && gtmId) {
      injectGTMSnippets(gtmId);
    }
  }, [loading, gtmId]);

  if (loading) {
    return null;
  }

  return (
    <>
      {header}
      <CheckLogin />
      <section className="main-wrapper">
        <div className={classname}>
          {title}
          <Routes>
            <Route path="/" element={login} />
            <Route path="/register" element={<Register />} />
            <Route path="/account" element={<Account />} />
            <Route path="/legacy" element={<GetMail />} />
            <Route path="/recovery" element={<LostPassword />} />
            <Route path="/support" element={<Support />} />
            <Route path="/waiting-for-approval" element={<Waiting />} />
            <Route path="/verify" element={<VerificationSuccess />} />
            <Route path="/verification" element={<Verify />} />
            <Route path="/legacy-pw" element={<FromLegacy />} />
            <Route
              path="/legacy-not-avaliable"
              element={<LegacyMaintenance />}
            />
          </Routes>
        </div>
      </section>
      {footer}
    </>
  );
};

function App() {
  // eslint-disable-line
  if (isDebugging()) {
    document.documentElement.style.setProperty("--debug-visible", "block");
  }

  return (
    <div className="App">
      <ConfigProvider>
        <BrowserRouter>
          <Main />
        </BrowserRouter>
      </ConfigProvider>
    </div>
  );
}

export default App;
