import React from "react";
import { Trans } from "@lingui/react";

const LegacyMaintenance = () => {
  const tokens = {
    0: <br />,
    1: <strong />,
  };
  return (
    <p>
      <Trans id={"maintenance.info"} components={tokens} values={tokens} />
    </p>
  );
};

export default LegacyMaintenance;
