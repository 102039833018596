import axios, { AxiosResponse } from "axios";
import {
  InitVerificationResult,
  KratosError,
  KratosNode,
  RecoveryFinishBody,
  VerificationFinishBody,
  RecoveryFinishResponseData,
  RecoveryInitResponseData,
  VerificationResponseData,
} from "./kratos.dto";

export type Config = {
  baseUrl: string;
  baseAdminUrl: string;
};
export type BaseResponse = {
  success: boolean;
  errors: string[];
};

export type InitBrowserVerificationResult = BaseResponse & {
  csrfToken: string | null;
  flowId: string | null;
};

export type InitBrowserRecoveryResult = BaseResponse & {
  csrfToken: string | null;
  flowId: string | null;
};

export type FinishBrowserRecoveryResult = BaseResponse;
export type FinishBrowserVerificationResult = BaseResponse;
const URLS = {
  RECOVERY: {
    BROWSER: {
      INIT: "/self-service/recovery/browser",
    },
    FINISH: "/self-service/recovery",
  },
  VERIFICATION: {
    BROWSER: {
      INIT: "",
    },
    FINISH: "/self-service/verification",
  },
};
export const ERROR_CODES = {
  GENERIC: {
    CANT_UNDERSTAND_INPUT: "MR::31f1692c-c744-4f16-81be-0ef62e008b76",
    IS_NOT_AXIOS_RESPONSE: "MR::8091d226-6fc9-462d-8999-92871b0a76ed",
    UNKNOWN_ERROR: "MR::759fe359-7e78-4db9-8f4f-022e8e272ee2",
  },
  RECOVERY: {
    IS_NOT_ERROR_BUT_STATUS_IS_NOT_200:
      "MR::270c29b7-dc75-4cd7-a983-868a4f174e12",
    CSRF_TOKEN_NOT_IN_RESPONSE: "MR::ab6b059e-b3e5-43db-b3af-1ffe68fff65d",
    FLOW_ID_NOT_IN_RESPONSE: "MR::7fc65e46-cede-4acc-9c48-3f03790dd9d2",
    TOKEN_EXISTS_BUT_EMPTY: "MR::736c5e10-5463-4d91-997e-2f523deb2e15",
  },
  VERIFICATION: {
    IS_NOT_ERROR_BUT_STATUS_IS_NOT_200:
      "MR::270c29b7-dc75-4cd7-a983-868a4f174e12",
    TIMEFRAME_EXHAUSTED: "MR::c9604de0-a400-4c8b-b0b2-52a34b4938cf",
    CSRF_TOKEN_NOT_IN_RESPONSE: "MR::ab6b059e-b3e5-43db-b3af-1ffe68fff65d",
    FLOW_ID_NOT_IN_RESPONSE: "MR::7fc65e46-cede-4acc-9c48-3f03790dd9d2",
    TOKEN_EXISTS_BUT_EMPTY: "MR::736c5e10-5463-4d91-997e-2f523deb2e15",
  },
};

export const isKratosError = (maybeErr: unknown): maybeErr is KratosError => {
  // TODO: optimize this "test"
  if (!maybeErr) {
    return false;
  }
  if (
    typeof maybeErr === "object" &&
    Object.prototype.hasOwnProperty.call(maybeErr, "message")
  ) {
    return true;
  }
  return false;
};
const genericHandleErrorResponses = (response: unknown): string | false => {
  if (!response) {
    return ERROR_CODES.GENERIC.CANT_UNDERSTAND_INPUT;
  }
  if (!Object.prototype.hasOwnProperty.call(response, "status")) {
    return ERROR_CODES.GENERIC.IS_NOT_AXIOS_RESPONSE;
  }
  const aResponse = response as AxiosResponse;
  if (aResponse.status !== 200) {
    if (isKratosError(aResponse.data)) {
      return aResponse.data.message;
    } else {
      return ERROR_CODES.RECOVERY.IS_NOT_ERROR_BUT_STATUS_IS_NOT_200;
    }
  }
  return false;
};
/**
 * Starts a browser based recovery flow.
 * @param {Config} config  Config for the client.
 * @returns {Promise<InitBrowserRecoveryResult>} Result.
 */
const initBrowserRecovery = async (
  config: Config
): Promise<InitBrowserRecoveryResult> => {
  const url = config.baseUrl + URLS.RECOVERY.BROWSER.INIT;
  try {
    const response = await axios.get<RecoveryInitResponseData | KratosError>(
      url,
      {
        withCredentials: true,
        headers: {
          Accept: "application/json",
        },
      }
    );
    const error = genericHandleErrorResponses(response);
    if (error) {
      throw new Error(error);
    }
    const csrfNode: KratosNode | undefined = (
      response.data as RecoveryInitResponseData
    ).ui.nodes.filter((n) => n.attributes.name === "csrf_token")[0];
    if (!csrfNode) {
      throw new Error(ERROR_CODES.RECOVERY.CSRF_TOKEN_NOT_IN_RESPONSE);
    }
    if (!response.data.id) {
      throw new Error(ERROR_CODES.RECOVERY.FLOW_ID_NOT_IN_RESPONSE);
    }
    if (!csrfNode.attributes.value) {
      throw new Error(ERROR_CODES.RECOVERY.TOKEN_EXISTS_BUT_EMPTY);
    }

    return {
      csrfToken: csrfNode.attributes.value,
      flowId: response.data.id,
      success: true,
      errors: [],
    };
  } catch (e) {
    return {
      success: false,
      errors: [e as string],
      csrfToken: null,
      flowId: null,
    };
  }
};
const finishBrowserRecovery =
  (config: Config) =>
  async (
    csrfToken: string,
    flowId: string,
    email: string
  ): Promise<FinishBrowserRecoveryResult> => {
    const url = `${config.baseUrl}${URLS.RECOVERY.FINISH}?flow=${flowId}`;
    const data: RecoveryFinishBody = {
      email,
      method: "link",
      csrf_token: csrfToken,
    };
    try {
      const response = await axios.post<RecoveryFinishResponseData>(url, data, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
        },
      });
      const error = genericHandleErrorResponses(response);
      if (error) {
        throw new Error(error);
      }
      if (response.status === 200) {
        return {
          success: true,
          errors: [],
        };
      } else {
        return {
          success: false,
          errors: [ERROR_CODES.GENERIC.UNKNOWN_ERROR],
        };
      }
    } catch (e) {
      return {
        success: false,
        errors: [e as string],
      };
    }
  };

/**
 * Starts a browser based recovery flow.
 * @param {Config} config  Config for the client.
 * @returns {Promise<InitBrowserRecoveryResult>} Result.
 */
const initBrowserVerification = async (
  config: Config
): Promise<InitBrowserVerificationResult> => {
  const url = config.baseUrl + URLS.VERIFICATION.BROWSER.INIT;
  try {
    const response = await axios.get<InitVerificationResult | KratosError>(
      url,
      {
        withCredentials: true,
        headers: {
          Accept: "application/json",
        },
      }
    );
    const error = genericHandleErrorResponses(response);
    if (error) {
      throw new Error(error);
    }
    const csrfNode: KratosNode | undefined = (
      response.data as RecoveryInitResponseData
    ).ui.nodes.filter((n) => n.attributes.name === "csrf_token")[0];
    if (!csrfNode) {
      throw new Error(ERROR_CODES.RECOVERY.CSRF_TOKEN_NOT_IN_RESPONSE);
    }
    if (!response.data.id) {
      throw new Error(ERROR_CODES.RECOVERY.FLOW_ID_NOT_IN_RESPONSE);
    }
    if (!csrfNode.attributes.value) {
      throw new Error(ERROR_CODES.RECOVERY.TOKEN_EXISTS_BUT_EMPTY);
    }

    return {
      csrfToken: csrfNode.attributes.value,
      flowId: response.data.id,
      success: true,
      errors: [],
    };
  } catch (e) {
    return {
      success: false,
      errors: [e as string],
      csrfToken: null,
      flowId: null,
    };
  }
};

const finishBrowserVerification =
  (config: Config) =>
  async (
    flowId: string,
    token: string
  ): Promise<FinishBrowserVerificationResult> => {
    const url = `${config.baseUrl}${URLS.VERIFICATION.FINISH}`;
    const data: VerificationFinishBody = {
      flow: flowId,
      token,
    };
    try {
      const response = await axios.post<VerificationResponseData>(url, data, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
        },
      });
      const error = genericHandleErrorResponses(response);
      if (error) {
        throw new Error(error);
      }
      if (response.status === 200) {
        return {
          success: true,
          errors: [],
        };
      } else {
        return {
          success: false,
          errors: [ERROR_CODES.GENERIC.UNKNOWN_ERROR],
        };
      }
    } catch (e) {
      return {
        success: false,
        errors: [e as string],
      };
    }
  };
export {
  initBrowserRecovery,
  finishBrowserRecovery,
  initBrowserVerification,
  finishBrowserVerification,
};
